<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner auth-bg m-0 justify-content-center">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">My Munshi</h2>
      </b-link> -->
      <!-- /Brand logo-->

      <b-col
        class="d-flex justify-content-center auth-bg p-5"
        align-self="center"
      >
        <div>
          <b-col lg="12" class="px-xl-2 mx-auto">
            <b-card>
              <p class="text-center">
                <b-img
                  height="100px"
                  :src="require('@/assets/images/logo/logo_light.png')"
                />
              </p>
              <b-card-title class="mb-1 text-center">
                Forgot Password? 🔒
              </b-card-title>
              <b-card-text class="mb-2 text-center">
                Enter your mobile number below and we'll send you OTP to reset
                your password
              </b-card-text>

              <!-- form -->
              <validation-observer ref="simpleRules">
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="validationForm"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="forgot-password-mobile"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile"
                      rules="required|regex:^([0-9]+)$|min:10"
                    >
                      <b-form-input
                        id="forgot-password-mobile"
                        v-model="userMobile"
                        :state="errors.length > 0 ? false : null"
                        name="forgot-password-mobile"
                        placeholder="Mobile Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-overlay
                    :show="$store.state.resetPasswordStore.statusLoading"
                    rounded="sm"
                    variant="transparent"
                    opacity="0.5"
                    blur="2px"
                  >
                    <b-button type="submit" variant="primary" block>
                      Send OTP
                    </b-button>
                  </b-overlay>
                </b-form>
              </validation-observer>

              <p class="text-center mt-2">
                <b-link @click="$router.go(-1)">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-link>
              </p>
            </b-card>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      userMobile: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin !== 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let payload = {
            mobile: this.userMobile
          }
          this.$store
            .dispatch('resetPasswordStore/sendOTP', { ctx: this, payload })
            .then((status) => {
              console.log({ Status: status })
              if (status) {
                this.$router.push({
                  name: 'auth-verify-otp'
                })
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
